<template>
    <div v-if="isStaff" class="box has-background-grey-dark">
        <div class="invoice-header">
            <div class="left">
                <h3>ORDERS</h3>
            </div>
            <div class="right">
                <div class="controls">
                    <div class="button" @click="onUpdateAll()">
                        <p>Update All</p>
                        <span class="icon is-right">
                <i class="fas fa-check fa-sm"></i>
              </span>
                    </div>
                </div>
            </div>

        </div>
        <Timestamp :timestamp-data="timeData"/>
        <div v-for="entry in gridData.tables" :key="entry.name" class="invoice-body">
            <div class="invoice-card">
                <div class="invoice-section is-flex is-bordered">
                    <div class="end">
                        <h3>User: {{ entry.name }}</h3>
                    </div>
                </div>
                <div class="invoice-section">
                    <div class="flex-table">
                        <div class="table-container">
                            <table class="table is-hoverable is-fullwidth">
                                <thead>
                                <tr>
                                    <th scope="col">Strategy</th>
                                    <th v-if="!editheader" scope="col">Buy/Sell</th>
                                    <th v-if="!editheader" class="col">Quantity fill</th>
                                    <th v-if="editheader" class="col">Quantity fill</th>
                                    <th v-if="editheader" class="col">Quantity offset</th>

                                    <th class="col">Price</th>
                                    <th v-if="editheader" class="col" >Price offset</th>
                                    <th class="col" >Qty goal</th>
                                    <th v-if="!editheader" class="col">%Done</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">DTC</th>
                                    <th scope="col">Edit</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="orderlist, index in entry.data" :key="index" class="list"
                                    :class="{ disabled: orderlist.user_text!=entry.name }"
                                >
                                    <td v-if="!orderlist.edit">
                                        {{
                                            orderlist.order_description
                                        }} {{ (orderlist.drop) }}

                                    </td >

                                    <td v-else class="list list-edit">
                                        <div class="field has-addons"><label class="label" for="order_description"></label><div class="control">
                                        <input readonly="" disabled="" class="input is-disabled" v-model="orderlist.order_description" type="text"  />
                                        </div></div>
                                    </td>
                                    <td v-if="!orderlist.edit" :style="[ (orderlist.verb_str ==='Sell') ? {'color':'#ad2100 !important', 'font-weight':'600'} : {'color':'#008100 !important', 'font-weight':'600'}]" :class="{ disabled: orderlist.edit===true }">
                                        {{ orderlist.verb_str }}
                                    </td>
                                  <td v-if="!orderlist.edit">
                                    {{
                                      orderlist.qty_fill
                                    }}

                                  </td >
                                    <td v-else class="list list-edit">
                                        <div class="field has-addons"><label class="label" for="qty_fill"></label><div class="control">
                                            <input readonly="" disabled="" class="input is-disabled" v-model="orderlist.qty_fill" type="text" />
                                        </div></div>
                                    </td>
                                    <td v-if="orderlist.edit" class="list list-edit">
                                        <div class="field has-addons"><label class="label" for="qty_offset"></label><div class="control">
                                            <input class="input" v-model="orderlist.qty_offset" type="number"  />
                                        </div></div>
                                    </td>
                                    <td v-if="!orderlist.edit" :class="{ disabled: orderlist.edit===true }" :style="[ (orderlist.price_offset >0) ? {'color':'#8aaeff !important'} :'',
                                                            (orderlist.price_offset <0 ) ? {'color':'#8aaeff !important'} :'']"
                                    >
                                      {{ (orderlist.order_value + orderlist.price_offset).toFixed(4) }}
                                    </td>
                                    <td v-else class="list list-edit">
                                    <div class="field has-addons"><label class="label" for="order_value"></label><div class="control">
                                        <input class="input"  v-model="orderlist.order_value" type="text" />
                                    </div></div>
                                    </td>

                                    <td v-if="orderlist.edit" class="list list-edit">
                                    <div class="field has-addons"><label class="label" for="price_offset"></label><div class="control">
                                        <input class="input" v-model="orderlist.price_offset" type="number" />
                                    </div></div>
                                    </td>
                                    <td v-if="!orderlist.edit" :class="{ disabled: orderlist.edit===true }"
                                    >
                                        {{ orderlist.qty_goal }}
                                    </td>
                                    <td v-if="!orderlist.edit" :style="[ (orderlist.qty_offset >0) ? {'color':'#8aaeff !important'} :'',
                                                            (orderlist.qty_offset <0 ) ? {'color':'#8aaeff !important'} :'']">
                                        {{ ((orderlist['qty_fill']+orderlist.qty_offset)/(orderlist['qty_goal']+orderlist.qty_offset)*100).toFixed(0) }} %
                                    </td>
                                    <td v-else class="list list-edit">
                                        <div class="field has-addons"><label class="label" for="qty_goal"></label><div class="control">
                                            <input readonly="" disabled="" class="input is-disabled" v-model="orderlist.qty_goal" type="text" />
                                        </div></div>
                                    </td>

                                    <td v-if="!orderlist.edit" :style="[ (orderlist.trading_status_str ==='Suspended') ? {'color':'#ff3000 !important'} :'',
                                                            (orderlist.trading_status_str === 'Deleted') ? {'color':'#ff3000 !important'} :'',
                                                            (orderlist.trading_status_str=== 'Active') ? {'color':'#03f503 !important'} :'']" >
                                        {{ orderlist.trading_status_str }}
                                    </td>
                                    <td v-else class="list list-edit">
                                    <div class="field has-addons"><label class="label" for="product_name"></label><div class="control">
                                        <input readonly="" disabled="" class="input is-disabled" v-model="orderlist.trading_status_str" type="text"  />
                                    </div></div>
                                    </td>
                                    <td v-if="!orderlist.edit" :style="[ (orderlist.dtc ===true) ? {'background-color':'green !important', 'font-weight':'600'} : {'background-color':'#660000 !important', 'font-weight':'600'}]" >
                                    </td>
                                    <td v-else class="list list-edit">
                                        <label  class="checkbox is-outlined is-info">
                                            <input  type="checkbox" :checked="orderlist.dtc" v-model="orderlist.dtc">
                                            <span></span>
                                        </label>

                                    </td>
                                    <td v-if="!orderlist.edit" >
                                        <div class="button" @click="editItem(index, orderlist.user_text, gridData)" >
              <span class="icon is-right">
                <i class="fas fa-pen fa-sm"></i>
              </span>
                                        </div>
                                    </td>
                                    <td v-if="orderlist.edit" class="has-background-warning">
                                        <div class="field">
                                            <div class="control" style="display:inline-flex" >
                                                <a class="button is-info mr-2" >
                    <span class="icon is-left" @click="saveItem(orderlist,index, orderlist.user_text, gridData)">
                      <i class="fas fa-check fa-sm"></i>
                    </span>
                                                </a>
                                                <a class="button is-danger" @click="cancelEdit(index, orderlist.user_text, gridData)">
                    <span class="icon is-right">
                      <i class="fas fa-times fa-sm"></i>
                    </span>
                                                </a>
                                            </div>
                                        </div>
                                    </td>


                                </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="button" @click="onUpdateAll()">
                <p>Update All</p>
                <span class="icon is-right">
                <i class="fas fa-check fa-sm"></i>
              </span>
            </div>
        </div>
    </div>
</template>
<script>
import {ref, reactive, toRefs} from 'vue';
import store, {ActionTypes} from "../../store";
import {mapState, mapGetters, mapActions} from 'vuex'
import instance from "../../services/fetch";
import useNotyf from '../../composable/useNotyf'
import ordersList from "../../pages/orders/OrdersList.vue";

const notif = useNotyf()
export default {
    components: {},
    setup() {
        const oData = reactive({
            order_id: '',
            order_description: '',
            user_text: '',
            drop: 0,
            qty_goal: 0,
            verb_str: '',
            qty_fill: 0,
            order_value: 0,
            trd_value_avg: 0,
            editRowValue: null,
            edit: false,
            editheader: false,
            qty_offset: 0,
            price_offset: 0,
            dtc: false,

        });

        let showEdit = ref(false);

        const showUpdateForm = (entry) => {
            showEdit.value = true;
            oData.order_description = entry['order_description'];
            oData.user_text = entry['user_text'];
            oData.drop = entry['drop'];
            oData.qty_goal = entry['qty_goal'];
            oData.verb_str = entry['verb_str'];
            oData.qty_fill = entry['qty_fill'];
            oData.order_value = entry['order_value'];
            oData.price_offset = entry['price_offset'];
            oData.trd_value_avg = entry['trd_value_avg'];
            oData.edit = entry['edit'];
            oData.qty_offset = entry['qty_offset'];
            oData.price_offset = entry['price_offset'];
            oData.dtc = entry['dtc'];
        }


        const onUpdate = () => {
            //console.log(oData)
            store.dispatch(ActionTypes.FETCH_ORDERS).then(() => {
                this.gridData = this?.orders

                //notif.info(gData.order_description + ' Updated!!')
            });
        }

        return {
            showEdit,
            showUpdateForm,
            ...toRefs(oData),
            onUpdate,
        }
    },
    data() {
        return {
            gridColumns: ['order_description', 'user_text', 'drop', 'qty_goal', 'verb_str', 'qty_fill', 'order_value', 'trd_value_avg', 'qty_offset','price_offset','dtc'],
            usersListColumn: ['user_text'],
            userGridData: Array,
            gridData: Array,
            timeData: 'Loading...',
            optionsBase: {
                searchable: false,
                sortable: false,
                perPageSelect: false,
                data: {
                    headings: ['Strategy', 'Buy/Sell', 'Quantity', 'Price', 'Status'],
                    data: []
                }
            }
        }
    },
    computed: {
        ordersList() {
            return ordersList
        },
        ...mapState(['user', 'orders', 'orderusers']),
        ...mapGetters([
            'isStaff'
        ]),
        previousList: function (item) {
            if (this.prev.indexOf(item) == -1) {
                return false;
            }
            return true;
        }

    },

    mounted() {
        store.dispatch(ActionTypes.FETCH_ORDERS).then(() => {
            this.gridData = this.orders
        });

    },
    methods: {
        onUpdateAll() {
            store.dispatch(ActionTypes.FETCH_ORDERS).then(() => {
                this.gridData = this.orders
            });
            setTimeout(this.onUpdateAll, 6800);
        },
        editItem(index, table_name, table) {
            const currentTable = table.tables.find(t=>t.name === table_name);
            //const currentTable = this.tables.find(t => t.name === table);
            currentTable.data[index].edit = true;
            this.editheader=true;

        },
            saveItem(gData, index, table_name, table) {
                if(gData.order_id) {
                    notif.info(gData.order_id + ' Updated!!')
                    const options = {
                        method: 'post',
                        url: 'update_order_data/',
                        data: {
                            "order_id":gData.order_id,
                            "qty_offset":gData.qty_offset ? gData.qty_offset : 0,
                            "price_offset":gData.price_offset ? gData.price_offset : 0,
                            "dtc":gData.dtc ? gData.dtc : false,
                        },
                    }
                    const currentTable = table.tables.find(t=>t.name === table_name);
                    //const currentTable = this.tables.find(t => t.name === table);
                    currentTable.data[index].edit = false;
                    this.editheader=false;
                    instance(options)

                }
            },
        cancelEdit(index, table_name, table) {
            const currentTable = table.tables.find(t=>t.name === table_name);
            currentTable.data[index].edit = false;
            this.editheader=false;
        }
    }
}
</script>

<style lang="scss">

.currenttime {
    padding: 10px;
    font-weight: 900;
}

table {
    border-radius: 3px;


}


.list.disabled {
    display: none;
}

@media (max-width: 767px) {
    .view-wrapper {
        width: 100% !important;
        margin-left: 0 !important;
        // margin-top: 10px !important;
    }
}

@media screen and (max-width: 992px) {
    table {
        overflow-x: auto;
        /* line-height: 0.65; */
    }

}

.yyielddrift {
    color: #8aaeff !important;
    font-weight: bold;
}

.tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}

.strat_up {
    -webkit-animation-name: flash;
    -webkit-animation-duration: 7s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: ease-in-out;
    -webkit-animation-direction: alternate;
}

.strat_down {
    -webkit-animation-name: flash2;
    -webkit-animation-duration: 2s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: ease-in-out;
    -webkit-animation-direction: alternate;
}

@-webkit-keyframes flash {
    from {
        color: #ffffff;
        font-weight: bold;
    }

    to {
        color: #a7a7a7;
        font-weight: bold;
    }

}

@-webkit-keyframes flash2 {
    from {
        color: #ffffff;
        font-weight: bold;
    }

    to {
        color: #a7a7a7;
        font-weight: bold;
    }

}

th, td {
    padding: 0.4em 0.2em !important;
    text-align: right !important
}

th.active {
    color: #fff;
}

th.active .arrow {
    opacity: 1;
}

#gridContainer span.current-value {
    display: inline-block;
    margin-right: 5px;
}

#gridContainer span.diff {
    width: 50px;
    display: inline-block;
}

.inc_a span {
    color: #81d76e !important;
    text-align: right !important;
}

.inc_a {
    color: #ababab;
    font-weight: bold;
}

.dec_a {
    color: #ff0000;
    font-weight: bold;
}

.dec_a span {
    color: #db5f5f;
    text-align: right !important;

}

.close {
    color: #f8e12f;
    text-align: right !important
}

.strat {
    text-align: left !important;
    padding-left: 6px !important;
}

.strat_r {
    text-align: right !important;
}

.price {
    text-align: left !important;
    padding-left: 47px !important;
}

.change {
    padding-left: 24px !important;
}

.inc_a .arrow,
.dec_a .arrow {
    display: inline-block;
    height: 10px;
    width: 10px;
    background-repeat: no-repeat;
    background-size: 10px 10px;
}

.inc_a .arrow {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAABGdBTUEAAK/INwWK6QAAABl0RVh0U29mdHdhcmUAQWRvYmUgSW1hZ2VSZWFkeXHJZTwAAADKSURBVHjaYtTaLs1ABEiG0nPRJa56PEHhsxBhmCUQT4OyrwHxcXyKmQgYJgHE64CYDYrXQcXIMhCbAcgWkGzgNKh38QUB0QamIUUErkhKI9ZAGyCeTERkTYaqxWsgKA2txhdG6GGsvUNGGpeBRMUiGhCFGsqGzUBQQJsxkA5AemaiG5hDIBIIgQSgK0FmMDACs549kN5FZLjhA7+A2A2U9YSAOBeLAk4gnoBDczoOcSFGPIUDPxB/wCHHiKtwYGKgMhg1cBAaCBBgAJTUIL3ToPZfAAAAAElFTkSuQmCC');
}

.dec_a .arrow {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAABGdBTUEAAK/INwWK6QAAABl0RVh0U29mdHdhcmUAQWRvYmUgSW1hZ2VSZWFkeXHJZTwAAADJSURBVHjaYvzPgBfgkhYA4o8QFahKmBioDEYNHIQGsgBxIBCLkqgvAYi/g1mMjMjir0EJzR6If/6HpChKMMgMe3DKBeIcKhiY8x/MYoDj+RQYNgdkGLqBbEB8kgzDToL1YjEQhKWB+BUJhj0H64Eahs1AELYhMpJ+gtUiGYbLQBBOI8LANLBaIg1kAAc0vkiAqSPBQFAkHcNi2DGoHMkGgrAENOCRI0ECRQ2JBoKwJTQCfkLZDPgMZPxPXN5NhtJzMSsJVBMAAgwAyWSY2svfmrwAAAAASUVORK5CYII=');
}

.container-load {
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;

    i {
        color: #fff;
    }
}

.list-edit {
    input {
        font-size: 1.1rem;

        &.is-disabled {
            opacity: 0.6;
        }

        &[name="exchangeName"] {
            font-size: 1.5rem;
            font-weight: 900;
        }
    }
}
.disabled{
    display: none;
}
</style>
